import React, { FunctionComponent, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";
import { Col, Row, Table } from "react-bootstrap";

import { useUsers } from "../../hooks/useUsers";
import { useAuthentication } from "../../hooks/useAuthentication";
import { ContentLayout } from "../common/Layout";
import { useUserContext } from "../../contexts/UserContext";
import { User } from "../../models/user/User";

interface MatchParams {
  userId: string;
}
interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
}

const Admin: FunctionComponent<Props> = (props: Props) => {
  const history = useHistory();
  const { setActiveUser } = useUserContext();
  const [users, usersLoading, usersError] = useUsers();

  useAuthentication(props.isAuthenticated);

  useEffect(() => {
    if (!props.isAuthenticated) {
      history.push("/login");
    }
  }, [props.isAuthenticated, history]);

  const handleUserSelect = (user: User): void => {
    setActiveUser(user);
    history.push(`/user/${user.id}/dashboard`);
  };

  if (usersError) return <p>Fehler beim Laden der Daten</p>;

  return (
    <ContentLayout title="Adminzentrale" siteTitle="Spielgruppe Online - Adminzentrale" isLoading={usersLoading}>
      <Row className="py-5">
        <Col>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>E-Mail</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index} onClick={() => handleUserSelect(user)} style={{ cursor: "pointer" }}>
                  <td>{user.id}</td>
                  <td>{user.email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </ContentLayout>
  );
};

export default Admin;
