import React, { createContext, useState, useContext, ReactNode } from "react";

import { User } from "../models/user/User";

interface UserContextType {
  activeUser: User | null;
  setActiveUser: (user: User) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [activeUser, setActiveUser] = useState<User | null>(null);

  return <UserContext.Provider value={{ activeUser, setActiveUser }}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
