import React from "react";
import ReactDOM from "react-dom";

import "./index.scss";
import App from "./views/App";
import { UserProvider } from "./contexts/UserContext";

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <App />
    </UserProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
