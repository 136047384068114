import React, { FunctionComponent, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";

import SignIn from "./auth/SignIn";
import Registrations from "./registration/Registrations";
import UpdateRegistrationEditor from "./registration/UpdateRegistrationEditor";
import Limits from "./limit/Limits";
import Navbar from "./common/navbar/Navbar";
import UpdateLimitEditor from "./limit/UpdateLimitEditor";
import Dashboard from "./dashboard/Dashboard";
import Admin from "./admin/Admin";
import { Settings } from "./settings/Settings";
import CreateRegistrationEditor from "./registration/CreateRegistrationEditor";
import CreateLimitEditor from "./limit/CreateLimitEditor";
import { useAuthUser } from "../hooks/useAuthUser";
import { firebaseAppAuth } from "../lib/firebase";
import { useAuthAdmin } from "../hooks/useAuthAdmin";
import { GlobalErrorBoundary } from "../core/GlobalErrorBoundary";
import SignUp from "./auth/SignUp";
import { Form } from "./form/Form";
import { Email } from "./email/Email";
import { SpielgruppePlus } from "./premium/SpielgruppePlus";
import { Footer } from "./common/footer/Footer";
import { Datenschutz } from "./staticsites/Datenschutz";
import { Agb } from "./staticsites/Agb";
import { PublicRegistrationForm } from "./registrationform/PublicRegistrationForm";
import ResetPassword from "./auth/ResetPassword";
import { enableAutoPageviews } from "../helper/analytics";
import { useUserContext } from "../contexts/UserContext";

const App: FunctionComponent = () => {
  const user = useAuthUser();
  const { activeUser } = useUserContext();
  const isAuthenticated = !!user;
  const isAdmin = useAuthAdmin(user);

  useEffect(() => {
    return enableAutoPageviews();
  }, []);

  return (
    <div className={"h-100"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Webtie Anmeldungen</title>
        <meta name="description" content="Anmeldungen online verwalten" />
      </Helmet>
      <GlobalErrorBoundary>
        <>
          <Router>
            {user && (
              <Navbar userId={activeUser?.id || user.uid} isAdmin={isAdmin} signOut={() => firebaseAppAuth.signOut()} />
            )}
            <Switch>
              <Route
                exact
                path={"/user/:userId/dashboard"}
                render={(props) => <Dashboard {...props} isAuthenticated={isAuthenticated} />}
              />
              <Route
                exact
                path={"/user/:userId/registrations/create"}
                render={(props) => <CreateRegistrationEditor {...props} isAuthenticated={isAuthenticated} />}
              />
              <Route
                exact
                path={"/user/:userId/registrations/:registrationId"}
                render={(props) => <UpdateRegistrationEditor {...props} isAuthenticated={isAuthenticated} />}
              />
              <Route
                exact
                path={"/user/:userId/limits/create"}
                render={(props) => <CreateLimitEditor {...props} isAuthenticated={isAuthenticated} />}
              />
              <Route
                exact
                path={"/user/:userId/limits/:limitId"}
                render={(props) => <UpdateLimitEditor {...props} isAuthenticated={isAuthenticated} />}
              />
              <Route
                exact
                path={"/user/:userId/limits"}
                render={(props) => <Limits {...props} isAuthenticated={isAuthenticated} />}
              />
              <Route
                exact
                path={"/user/:userId/form"}
                render={(props) => <Form {...props} isAuthenticated={isAuthenticated} />}
              />
              <Route
                exact
                path={"/user/:userId/email"}
                render={(props) => <Email {...props} isAuthenticated={isAuthenticated} />}
              />
              <Route
                exact
                path={"/user/:userId/registrations"}
                render={(props) => <Registrations {...props} isAuthenticated={isAuthenticated} />}
              />
              <Route
                exact
                path={"/user/:userId/admin"}
                render={(props) => <Admin {...props} isAuthenticated={isAuthenticated} />}
              />
              <Route
                exact
                path={"/user/:userId/settings"}
                render={(props) => <Settings {...props} isAuthenticated={isAuthenticated} isAdmin={isAdmin} />}
              />
              <Route
                exact
                path={"/user/:userId/plus"}
                render={(props) => <SpielgruppePlus {...props} isAuthenticated={isAuthenticated} />}
              />
              <Route exact path={"/signup"} render={(props) => <SignUp {...props} user={user} />} />
              <Route exact path={"/reset-password"} render={(props) => <ResetPassword {...props} user={user} />} />
              <Route path="/formular/:userId" render={(props) => <PublicRegistrationForm {...props} />} />
              <Route path="/datenschutz">
                <Datenschutz />
              </Route>
              <Route path="/agb">
                <Agb />
              </Route>
              <Route path="/">
                <SignIn user={user} />
              </Route>
            </Switch>
            <Footer />
          </Router>
        </>
      </GlobalErrorBoundary>
      <ToastContainer />
    </div>
  );
};

export default App;
